export enum ProductType {
  membership = "membership",
  license = "license",
  inscription = "inscription",
  clothing = "clothing",
  epass = "epass",
  format = "format",
  additionalProduct = "product",
  participant = "participant",
}

export class ProductHelper {
  static epass = "epass";
  static format = "format";
  static membership = "membership";
  static license = "license";
  static inscription = "inscription";
  static clothing = "clothing";
  static discount = "discount";

  static listProduct() {
    const listProduct = [
      {
        name: "Adhésions",
        value: "membership",
        selected:0
      },
      {
        name: "Licences",
        value: "license",
        selected:0
      },
      {
        name: "Frais d'inscription",
        value: "inscription",
        selected:0
      },
      {
        name: "Vêtements",
        value: "clothing",
        selected:0
      },
      {
        name: "Réservation",
        value: "participant",
        selected:0
      },
      {
        name: "Epass",
        value: "epass",
        selected:0
      },
      {
        name: "Modalités",
        value: "modality",
        selected:0
      },
      {
        name: "Réduction",
        value: "discount",
        selected:0
      },
    ];

    return listProduct;
  }

  static listProductComplement() {
    const listProduct = [
      {
        name: "Adhésion",
        value: "membership",
      },
      {
        name: "Licence",
        value: "license",
      },
      {
        name: "Réservation",
        value: "participant",
      },
      {
        name: "Epass",
        value: "epass",
      },
      {
        name: "Modalités",
        value: "modality",
      },
    ];

    return listProduct;
  }

  static listProductPromotion() {
    const listProduct = [
      {
        name: "Réduction",
        value: "discount",
      },
    ];

    return listProduct;
  }
}
